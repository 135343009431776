<template>
    <div class="icon-thumbnail">
        <div :class="`icon-thumbnail__icon ${[ editable ? 'editable' : '' ]} `" @click="this.$parent.updateComponentIndex(id); openModal('changeIconModal')">
            <inline-svg :src="require(`../assets/svg/${icon}.svg`)" />
        </div>
        <input v-if="editable" class="icon-thumbnail__text" type="text" placeholder="Title" :value="text" @input="$emit('update:text', $event.target.value);" @focus="this.$parent.onFocus" @blur="this.$parent.onBlur">
        <p v-else class="icon-thumbnail__text">{{ text }}</p>
    </div>
</template>

<script>
    import * as bootstrap from 'bootstrap';
    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'IconThumbnail',

        components: {
            InlineSvg,
        },

        props: {
            editable: {
                type: Boolean,
                required: false,
                default: false,
            },
            icon: {
                type: String,
                required: false,
            },
            id: {
                // type: Number,
                required: false,
            },
            text: {
                type: String,
                required: false,
            },
        },

        methods: {
            openModal(modalId) {
                const myModalEl = document.getElementById(modalId);
                const modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
                modal.toggle();
            }
        }
    }
</script>
