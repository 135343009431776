<template>
    <div class="p-new-persona-role">
        <div class="container-xl">
            <PageHeader title="Spotify" goBack />
            <div class="bg-white p-4 mt-4">
                <div class="d-flex justify-content-around">
                    <Badge text="Job" variant="light" icon="icon-heart" />
                    <Badge text="Keyword" variant="primary" />
                </div>
                <div class="mt-3">
                    <Button text="Add a tag" icon="icon-plus" iconPosition="left" variant="link" />
                </div>
                <div class="mt-3">
                    <IconThumbnail icon="icon-book" text="Rebecca’s story" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '../components/Button.vue';
    import IconThumbnail from '../components/IconThumbnail.vue';
    import PageHeader from '../components/PageHeader.vue';
    import Badge from '../components/Badge.vue';
    
    export default {
        name: 'NewPersonaRole',

        components: {
            Button,
            IconThumbnail,
            PageHeader,
            Badge,
        },
    }
</script>
